/* eslint-disable import/prefer-default-export */
import CONST from '@/utils/Constants/General';
import _has from 'lodash/has';

export const isTrue = value => value === true || value === 'true';
export const isLoqateEnabled = (() =>
  DLG.config.isLoqateEnabled === true ||
  DLG.config.isLoqateEnabled === 'true')();
export const isAddressVerify = (() =>
  DLG.config.isAddressVerify === true ||
  DLG.config.isAddressVerify === 'true')();
export const isVatSenseEnabled = (() =>
  DLG.config.isVatSenseEnabled === true ||
  DLG.config.isVatSenseEnabled === 'true')();
export const klarnaEnabled = (() =>
  DLG.config.klarnaEnabled === true || DLG.config.klarnaEnabled === 'true')();
export const affirmEnabled = (() =>
  DLG.config.affirmEnabled === true || DLG.config.affirmEnabled === 'true')();
export const isAUCountry = isocode => CONST.ISO.AU === isocode;
export const isITSite = CONST.ISO.IT === DLG.gigya.country;
export const isNLSite = CONST.ISO.NL === DLG.gigya.country;
export const isUSSite = CONST.ISO.US === DLG.gigya.country;
export const isJpSite = (() => CONST.SHOP.JP === DLG.gigya.country)();
export const isHRSite = CONST.ISO.HR === DLG.gigya.country;
export const isUSCASite = (() =>
  CONST.SHOP.USA === DLG.gigya.country ||
  CONST.SHOP.CA === DLG.gigya.country)();
export const isArrayNotEmpty = d => d && Array.isArray(d) && d.length > 0;
export const isNumber = n => n && !Number.isNaN(n);
export const isBraun = DLG.config.brand === CONST.BRAND.BRAUN;
export const isDelonghi = DLG.config.brand === CONST.BRAND.DELONGHI;
export const isKenwood = DLG.config.brand === CONST.BRAND.KENWOOD;
export const isNutribullet = DLG.config.brand === CONST.BRAND.NUTRIBULLET;
export const isEcommerce = (() => isTrue(DLG.config.eCommerceEnabled))();
export const isInvoiceEnabled = (() =>
  !isTrue(DLG.config.isInvoiceToHide) && isTrue(DLG.config.isLogged))(); // Labeled as B2C Invoicing Disabled
export const isExpressCheckoutEnabled = (() =>
  !isTrue(DLG.config.expressCheckoutEnabled))();
export const isStep1 = () => window.location.href.toString().includes('step1');
export const isStep2 = () => window.location.href.toString().includes('step2');
export const isStep3 = () => window.location.href.toString().includes('step3');
export const isStep4 = () => window.location.href.toString().includes('step4');

export const isSafariMobile = (() => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
})();

export const enterInViewport = elem => {
  const distance = elem.getBoundingClientRect();
  return distance.top <= 230;
};
export const hasValue = (obj, attr) => _has(obj, attr) && obj[attr];
export const hasPositiveValue = (obj, attr) => _has(obj, attr) && obj[attr] > 0;

export const showVATIncludedInCartAndOrderHistory = (() =>
  isTrue(DLG.config.showVATIncludedInCartAndOrderHistory))();

export const showVATIncludedInCartSummaryAndCheckoutAndEmail = (() =>
  isTrue(DLG.config.showVATIncludedInCartSummaryAndCheckoutAndEmail))();

export const showVATIncludedInMinicart = (() =>
  isTrue(DLG.config.showVATIncludedInMinicart))();

export const eventLoaded = eventName =>
  Object.keys(DLG.EVE?._events).includes(eventName);

// For normal screens the pixel ratio is 1 for retina displays it is 2
export const userHasZoom = () =>
  window.devicePixelRatio !== 1 && window.devicePixelRatio !== 2;
